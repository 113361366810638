
export default {
  name: "jsbannerpt",
  props: {
    scriptCode: String,
    width: String,
    height: String,
    docId: String,
  },
  data() {
    return {
      adVisible: false,
      banners: [], // Menampung data banner dari API
    };
  },
  computed: {
    hasBanners() {
      return this.banners.length > 0 && this.banners[0].bannerImgPT !== "-";
    },
    bannerImage() {
      return this.hasBanners
        ? `https://olahbolamedia.sgp1.digitaloceanspaces.com/banner/${this.banners[0].bannerImgPT}`
        : "/image/banner-pt.png"; // Gambar default
    },
    bannerUrl() {
      return this.hasBanners
        ? this.banners[0].bannerUrl
        : "https://kitagaruda.id/products"; // URL default
    },
  },
  mounted() {
    // Fetching banner data from API
    fetch("/api/banner")
      .then((response) => response.json())
      .then((data) => {
        this.banners = data;
      })
      .catch((error) => {
        console.error("Error fetching banners:", error);
      });

    this.$postscribe(`#${this.docId}`, this.scriptCode);

    const observer = new MutationObserver(() => {
      if (document.querySelector(`#${this.docId} img`)) {
        this.adVisible = true;
        observer.disconnect();
      }
    });

    observer.observe(document.getElementById(this.docId), {
      childList: true,
      subtree: true,
    });
  },
  methods: {
    handleClick() {
      window.open(this.bannerUrl, "_blank");
    },
  },
};
